import React, { FC, Suspense } from 'react';
import { Seo } from 'src/public/components/Seo';
import { RouteComponentProps } from '@reach/router';

const VideoRoot = React.lazy(() => import('src/video/VideoPage'));

interface VideoPageProps extends RouteComponentProps {
  id: string;
}

const VideoPage: FC<VideoPageProps> = (props: VideoPageProps) => {
  return typeof document === 'undefined' ? (
    <Seo />
  ) : (
    <>
      <Seo />
      <Suspense fallback={<></>}>
        <VideoRoot id={props.id} />
      </Suspense>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default VideoPage;
